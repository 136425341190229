import type { FunctionComponent } from 'react';
import { Suspense, use } from 'react';
import { PageContext, ViewMode } from '../../contexts/page-context.tsx';
import { NotFound } from '../not-found/not-found.tsx';
import { ParseAndRenderPage } from '../ParseAndRenderPage.tsx';
import { ErrorBoundary } from '../RootErrorBoundary/error-boundary.tsx';

export const Page: FunctionComponent = () => {
  const pageContext = use(PageContext);
  if (pageContext.viewMode === ViewMode.NotFound) {
    // No title because it is set in the router.
    return <NotFound noTitle />;
  }
  const { page } = pageContext;
  return (
    page && (
      <ErrorBoundary>
        <Suspense>
          <ParseAndRenderPage componentsJSON={page.components} bodyStylesJSON={page.bodyStyles} />
        </Suspense>
      </ErrorBoundary>
    )
  );
};
