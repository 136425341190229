import {
  visualPreviewAuthTokenParam,
  visualPreviewObjectIdParam,
  visualPreviewObjectTypeParam,
} from '@wirechunk/lib/visual-preview.ts';

// The URL search params on page load.
const searchParams = new URLSearchParams(window.location.search);

// The value of the preview auth token parameter on page load.
export const visualPreviewAuthToken = searchParams.get(visualPreviewAuthTokenParam);

// The value of the preview object type parameter on page load.
export const visualPreviewObjectType = searchParams.get(visualPreviewObjectTypeParam);

// The value of the preview object ID parameter on page load.
export const visualPreviewObjectId = searchParams.get(visualPreviewObjectIdParam);
