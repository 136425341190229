import type { PreviewPageTemplateQuery } from '@wirechunk/lib/shared-queries/preview-page-template-query.generated.ts';
import type { PublicSitePageQuery } from '@wirechunk/lib/shared-queries/public-site-page-query.generated.ts';
import { createContext } from 'react';

export enum ViewMode {
  // An actual page (live mode).
  Live,
  // A page template.
  Preview,
  // No page found (live mode).
  NotFound,
}

export type PageContext =
  | {
      viewMode: ViewMode.Live | ViewMode.Preview;
      title: string;
      // TODO: Make this field required.
      page?:
        | NonNullable<PublicSitePageQuery['publicSite']['page']>
        | PreviewPageTemplateQuery['pageTemplate'];
    }
  | { viewMode: ViewMode.NotFound };

export const defaultPageContext: PageContext = Object.freeze({
  viewMode: ViewMode.Live,
  title: '',
});

export const PageContext = createContext<PageContext>(defaultPageContext);
