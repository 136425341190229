// Paths starting with double underscores are reserved for internal use.
export const visualPreviewPath = '__visual-preview';

// The name of the URL parameter that contains the visual preview auth token.
export const visualPreviewAuthTokenParam = 'wirechunkPreviewAuthToken';

// The name of the URL parameter that contains the object type to preview.
export const visualPreviewObjectTypeParam = 'wirechunkPreviewObjectType';

// The name of the URL parameter that contains the ID of the object to preview.
export const visualPreviewObjectIdParam = 'wirechunkPreviewObjectId';

export const visualPreviewObjectTypes = [
  'Component',
  'Form',
  'FormConfirmationMessageComponents',
  'FormTemplate',
  'FormTemplateConfirmationMessageComponents',
  'Lesson',
  'Page',
  'PageTemplate',
  'PageTemplatePropsSetup',
  'StageBlueprint',
  // TODO
] as const;

export type VisualPreviewObjectType = (typeof visualPreviewObjectTypes)[number];
